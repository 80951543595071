// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Sanin",
  middleName: "",
  lastName: "Alagic",
  message: " Software Developer | E-commerce and Betting Platforms | Symfony | Spring Boot | Shopware ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/sanikapanika",
    }
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/profile.jpg"),
  imageSize: 375,
  message:
    "I am Sanin Alagic, a software developer with extensive expertise in backend development, primarily focused on e-commerce and betting platforms. Educated at the Faculty of Information Technologies in Mostar, Bosnia and Herzegovina, I have developed a deep understanding of various technical domains. My technical skill set includes PHP, Java, Spring Boot and Shopware and I am adept at building scalable backend architectures and optimizing system performance. I am fluent in Bosnian and English, which enables me to communicate effectively and align with client objectives seamlessly. I am passionate about continuous learning, innovating solutions and mentoring junior developers, striving always to push the boundaries of technology and teamwork.",
  resume: require("../editable-stuff/resume.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Recent Projects",
  gitHubUsername: "sanikapanika", //i.e."johnDoe12Gh"
  reposLength: 4,
  specificRepos: [],
};

const projects = {
  show: true,
  heading: "Projects",
  projectsLength: 2,
  data: [
    {
      name: "Betting Platform",
      description: "Core of the betting platform and surrounding microservices, responsible for handling all the business logic, money transactions, data processing and much more. Designed and maintained high-performance microservices for betting platforms handling millions of money transactions daily, ensuring robust scalability and reliability during high-stake, real-time events. Spearheaded the integration of various software components and external systems to enhance overall platform functionality and data flow. Authored comprehensive unit tests to ensure system integrity and service level consistency.",
      languages: ["PHP", "Symfony", "Java", "Spring", "MySQL"]
    },
    {
      name: "Shopware",
      description: "Engineered scalable e-commerce solutions using Shopware and Shopify, tailored to meet specific client needs. Focused on creating robust backend architectures and integrating essential functionalities like payment gateways and inventory management systems. Actively collaborated with clients to convert business objectives into actionable digital strategies, ensuring enhanced customer analytics and user experience. Pioneered continuous improvement efforts by integrating the latest e-commerce technologies and practices.",
      languages: ["PHP", "Symfony", "MySQL", "JavaScript", "HTML", "CSS", "Twig"]
    }
  ]
}

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    {
      img: require("../editable-stuff/profile.jpg"),
      label: "First slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum."
    },
    {
      img: require("../editable-stuff/profile.jpg"),
      label: "Second slide label",
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum."
    },
  ],
  imageSize: {
    width: "615",
    height: "450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    {name: "PHP", value: 95},
    {name: "Symfony", value: 90},
    {name: "Java", value: 85},
    {name: "Spring Boot", value: 80},
    {name: "SQL", value: 90},
    {name: "RabbitMQ", value: 80},
    {name: "Kafka", value: 75},
    {name: "Redis", value: 80},
    {name: "JavaScript", value: 65},
    {name: "HTML/CSS", value: 55}
  ],
  softSkills: [
    {name: "Goal-Oriented", value: 80},
    {name: "Collaboration", value: 90},
    {name: "Positivity", value: 75},
    {name: "Adaptability", value: 85},
    {name: "Problem Solving", value: 90},
    {name: "Empathy", value: 90},
    {name: "Organization", value: 70},
    {name: "Creativity", value: 90},
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "I'm always eager to discuss potential projects and innovative ideas. If you're interested in collaborating or have any questions, please don't hesitate to reach out. Let’s create something great together!",
  email: "sanin.alagic@gmail.com",
};

const experiences = {
  show: false,
  heading: "Experiences",
  data: [
    {
      role: 'Software Engineer',// Here Add Company Name
      companylogo: require('../assets/img/dell.png'),
      date: 'June 2018 – Present',
    },
    {
      role: 'Front-End Developer',
      companylogo: require('../assets/img/boeing.png'),
      date: 'May 2017 – May 2018',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export {navBar, mainBody, about, repos, projects, skills, leadership, getInTouch, experiences};
